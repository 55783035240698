export const mapLetterGradeToVal = (letterGrade) => {
  switch (letterGrade) {
    case 'A+':
      return 4.0;
    case 'A':
      return 4.0;
    case 'A-':
      return 3.7;
    case 'B+':
      return 3.3;
    case 'B':
      return 3.0;
    case 'B-':
      return 2.7;
    case 'C+':
      return 2.3;
    case 'C':
      return 2.0;
    case 'C-':
      return 1.7;
    case 'D+':
      return 1.3;
    case 'D':
      return 1.0;
    case 'F':
      return 0.0;
    default:
      return 0.0;
  }
};

export const convertToList = (obj) => {
  let convertedList = [];
  for (let letterGrade in obj) {
    convertedList.push([letterGrade, obj[letterGrade]]);
  }
  return convertedList;
};

export const findIndex = (semName) => {
  return semName.search(/[0-9]/);
};
export const sortSems = (semArr) => {
  // Sort first according to seasons
  semArr.sort((sem1, sem2) => {
    let idx1 = findIndex(sem1.name);
    let idx2 = findIndex(sem2.name);

    let season1 = sem1.name.substring(0, idx1);
    let season2 = sem2.name.substring(0, idx2);

    let season1Priority = getSeasonPriority(season1);
    let season2Priority = getSeasonPriority(season2);

    //console.log(season1Priority);
    //console.log(season2Priority);

    if (season1Priority < season2Priority) return 1;
    else if (season1Priority > season2Priority) return -1;
    else return 0;
  });

  // sort according to year
  semArr.sort((sem1, sem2) => {
    let idx1 = findIndex(sem1.name);
    let idx2 = findIndex(sem2.name);

    let year1 = sem1.name.substring(idx1);
    let year2 = sem2.name.substring(idx2);

    if (year1 < year2) return 1;
    else if (year1 > year2) return -1;
    else return 0;
  });

  return semArr;
};

export const getSeasonPriority = (season) => {
  let priority = -1;
  switch (season.toLowerCase().trim()) {
    case 'fall':
      priority = 4;
      break;
    case 'winter':
      priority = 1;
      break;
    case 'spring':
      priority = 2;
      break;
    case 'summer':
      priority = 3;
      break;
    default:
      return;
  }
  return priority;
};

export const isFalsyValue = (value) => {
  return value === '' || value === null || value === undefined;
};
