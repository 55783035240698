import React, { useState, useEffect } from 'react';
import BaseForm from '../BaseForm.js';
import { axiosInstance } from '../../axiosApi';
import TextField from '@mui/material/TextField';
import { isFalsyValue } from '../util.js';

export const EditComponentForm = ({
  componentToBeEdited,
  components,
  setComponents,
  setFeedbackAvailable,
  isOpen,
  handleClose,
}) => {
  const originalEditingComponent = componentToBeEdited;

  const [editingComponent, setEditingComponent] = useState(componentToBeEdited);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isWeightEmpty, setIsWeightEmpty] = useState(false);
  const [isDueDateEmpty, setIsDueDateEmpty] = useState(false);
  const [hasTotalWeightExceededLimit, setHasTotalWeightExceededLimit] =
    useState(false);

  const nameAlreadyExists = (name) => {
    if (name === '') return;
    for (let component of components) {
      if (
        originalEditingComponent?.name?.toLowerCase() ===
        component.name?.toLowerCase()
      ) {
        continue;
      }
      if (component?.name?.toLowerCase() === name?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const handleCancel = () => {
    setEditingComponent({
      name: '',
      weight: '',
      grade_received: '',
      goal_grade: '',
      due_date: '',
    });
    setHasTotalWeightExceededLimit(false);
    setIsNameEmpty(false);
    setIsWeightEmpty(false);
    setIsDueDateEmpty(false);
    handleClose();
  };

  const dueDateFocusHanlder = (event) => {
    if (event.target.value === '') setIsDueDateEmpty(true);
    else setIsDueDateEmpty(false);
  };
  const gradeReceivedHandler = (event) => {
    //console.log(event.target.value);
    if (event.target.value === '')
      setEditingComponent((prev) => {
        return { ...prev, grade_received: event.target.value };
      });
    else
      setEditingComponent((prev) => {
        return { ...prev, grade_received: parseFloat(event.target.value) };
      });
  };
  const expectedGradeHandler = (event) => {
    if (event.target.value === '')
      setEditingComponent((prev) => {
        return { ...prev, goal_grade: event.target.value };
      });
    else
      setEditingComponent((prev) => {
        return { ...prev, goal_grade: parseFloat(event.target.value) };
      });
  };

  const calcWeight = () => {
    let sum = 0;
    for (let component of components) {
      if (originalEditingComponent?.uuid === component.uuid) {
        continue;
      }
      sum += component.weight;
    }
    return parseFloat(sum.toFixed(2));
  };

  const dueDateHandler = (event) => {
    setEditingComponent((prev) => {
      return { ...prev, due_date: event.target.value };
    });
    setIsDueDateEmpty(false);
  };
  const componentNameFocusHandler = (event) => {
    if (event.target.value === '') setIsNameEmpty(true);
    else setIsNameEmpty(false);
  };

  const hasInputError = () => {
    let hasError = false;

    if (editingComponent?.weight === '') {
      setIsWeightEmpty(true);
      hasError = true;
    } else {
      setIsWeightEmpty(false);
    }
    if (editingComponent?.name === '') {
      setIsNameEmpty(true);
      hasError = true;
    } else {
      setIsNameEmpty(false);
    }
    if (editingComponent?.due_date === '') {
      setIsDueDateEmpty(true);
      hasError = true;
    } else {
      setIsDueDateEmpty(false);
    }
    if (nameAlreadyExists(editingComponent?.name)) {
      hasError = true;
    }
    if (
      editingComponent?.grade_received > 100 ||
      editingComponent?.expected_grade > 100
    ) {
      hasError = true;
    }

    const weightLeft = parseFloat(Math.abs(100 - calcWeight()).toFixed(2));

    if (editingComponent?.weight > weightLeft) {
      alert('Total weight exceeds 100%, no can do!');
      setHasTotalWeightExceededLimit(true);
      hasError = true;
    } else {
      setHasTotalWeightExceededLimit(false);
    }

    return hasError;
  };

  const sortArrByDate = (comps) => {
    comps.sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date);
    });
    return comps;
  };

  const maxWeightYouCanAdd = parseFloat(100 - calcWeight()).toFixed(2);

  const handleEditComponent = async () => {
    if (hasInputError()) return;
    try {
      if (isFalsyValue(editingComponent?.grade_received)) {
        editingComponent.grade_received = -1;
      } else {
        setFeedbackAvailable(true);
      }
      if (
        !('goal_grade' in editingComponent) ||
        editingComponent?.goal_grade === ''
      ) {
        editingComponent.goal_grade = -1;
      }

      if (editingComponent?.total_time_studied_hours === '') {
        editingComponent.total_time_studied_hours = 0;
      }
      if (editingComponent?.total_time_studied_minutes === '') {
        editingComponent.total_time_studied_minutes = 0;
      }

      if (editingComponent?.total_time_studied_seconds === '') {
        editingComponent.total_time_studied_seconds = 0;
      }

      const response = await axiosInstance.put(
        `courses/components/${editingComponent?.uuid}`,
        { ...editingComponent }
      );

      setComponents((prevComponents) => {
        let result = [];
        for (let component of prevComponents) {
          if (component.uuid === response.data.uuid) result.push(response.data);
          else result.push(component);
        }
        // sort the components by date
        return sortArrByDate(result);
      });

      setEditingComponent({
        name: '',
        weight: '',
        grade_received: '',
        goal_grade: '',
        due_date: '',
      });

      handleClose();

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  const componentNameHandler = (event) => {
    if (event.target.value === '') setIsNameEmpty(true);
    else setIsNameEmpty(false);

    setEditingComponent((prev) => {
      return { ...prev, name: event.target.value };
    });
  };
  const weightHandler = (event) => {
    if (event.target.value === '') setIsWeightEmpty(true);
    else setIsWeightEmpty(false);

    if (
      event.target.value >
      Math.abs(100 - calcWeight() + originalEditingComponent?.weight)
    ) {
      setHasTotalWeightExceededLimit(true);
    } else {
      setHasTotalWeightExceededLimit(false);
    }

    setEditingComponent((prev) => {
      return { ...prev, weight: parseFloat(event.target.value) };
    });
  };

  const weightFocusHandler = (event) => {
    if (event.target.value === '') setIsWeightEmpty(true);
    else setIsWeightEmpty(false);
  };

  return (
    <div>
      <BaseForm
        isOpen={isOpen}
        title="Edit Component"
        description="Leave the grade received section empty unless you have already received a mark for it."
        confirmButtonText="edit"
        handleSubmit={handleEditComponent}
        handleCancel={handleCancel}
      >
        <TextField
          error={isNameEmpty || nameAlreadyExists(editingComponent?.name)}
          helperText={
            nameAlreadyExists(editingComponent?.name)
              ? 'This name already exists'
              : 'Component name is mandatory'
          }
          margin="dense"
          id="Component Name"
          label="Component Name"
          placeholder="Assignment 1"
          type="text"
          value={editingComponent?.name}
          fullWidth
          onChange={componentNameHandler}
          onFocus={componentNameFocusHandler}
          variant="standard"
        />
        <TextField
          error={
            isWeightEmpty ||
            editingComponent?.weight > 100 ||
            editingComponent?.weight > maxWeightYouCanAdd
          }
          helperText={
            editingComponent?.weight > maxWeightYouCanAdd
              ? `Total weight of all courses exceeds 100%, max weight you can add is ${maxWeightYouCanAdd}%`
              : 'Weight is mandatory and has to be between 0 and 100 (inclusive)'
          }
          margin="dense"
          id="Weight"
          label="Weight (%)"
          type="number"
          step={1.0}
          value={editingComponent?.weight}
          fullWidth
          placeholder="15"
          onChange={weightHandler}
          onFocus={weightFocusHandler}
          variant="standard"
        />
        <TextField
          error={editingComponent?.grade_received > 100}
          helperText={'Grade received has to be between 0 and 100 (inclusive)'}
          margin="dense"
          id="Grade Received"
          label="Grade Received (%)"
          placeholder="80"
          type="number"
          step={1.0}
          value={
            editingComponent?.grade_received === -1
              ? ''
              : editingComponent?.grade_received
          }
          fullWidth
          onChange={gradeReceivedHandler}
          variant="standard"
        />
        <TextField
          error={editingComponent?.goal_grade > 100}
          helperText={'Goal grade has to be between 0 and 100 (inclusive)'}
          autoFocus
          margin="dense"
          id="Goal Grade"
          label="Goal Grade (%)"
          type="number"
          step={1.0}
          value={
            editingComponent?.goal_grade === -1
              ? ''
              : editingComponent?.goal_grade
          }
          placeholder="85"
          fullWidth
          onChange={expectedGradeHandler}
          variant="standard"
        />
        <TextField
          error={isDueDateEmpty}
          helperText={'Due date is mandatory'}
          margin="dense"
          id="Due date"
          label="Due date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={editingComponent?.due_date.slice(0, 10)}
          fullWidth
          onChange={dueDateHandler}
          onFocus={dueDateFocusHanlder}
          variant="standard"
        />
      </BaseForm>
    </div>
  );
};
